<template>
  <app-dialog
      v-model="manageCancelSortieDialog"
      :loading="loading"
      title="Cancel"
      question-title="Cancel the Sortie?"
      v-on:togglePrimary="cancelSortie()"
      v-on:toggleSecondary="closeDialog"
      v-if="activeSortie">
    Are you sure you want to cancel this Sortie?
  </app-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import AppDialog from "@/components/app/Dialog";

export default {
  name: "CancelSortieModal",
  components: {AppDialog},
  props: {
    loading: {
      type: Boolean
    },
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("sorties", ["manageCancelSortieDialog", "activeSortie"]),
  },

  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setManageCancelSortieDialog","setActiveSortie","setSorties" ]),
    async closeDialog() {
      await this.setActiveSortie(null)
      await this.setManageCancelSortieDialog(false)
    },

    async cancelSortie() {
      try {
        await this.setLoading(true)
        await SortieHttp.cancelSortie(this.activeSortie._id);
        this.$emit('refreshSorties')
        await this.closeDialog()
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      } finally {
        await this.setLoading(false)
      }
    }
  }
}
</script>
