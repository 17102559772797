<template>
  <div>
    <v-alert
        border="left"
        colored-border
        type="warning"
        elevation="2"
        v-for="g in canFlyNotifications" :key="g._id">
      <div class="text-h6">{{g.groupName}} Flights Functionality restricted.</div>
      <div class="text-body-2">The Flights functionality is currently been limited. You have 1 or more notifications
        connected with operating an aircraft or urgent announcement that you need to read (and sign) before you can fly
        as a member of this group.
      </div>
      <div class="text-body-2">Click the
        <v-icon>mdi-bell-ring</v-icon>
        icon on the top toolbar of the page. You need to (as a minimum) action those notifications with a
        <v-btn x-small fab color="error"></v-btn>
        beside them.
      </div>
    </v-alert>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CanFlyNotification",
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapState("user", ["flyingStatus", "accountRoles"]),
    ...mapState("group", ["groups"]),

    canFlyNotifications() {
      return this.items ? this.items.filter(g => !g.canFly && g.groupName !=='Notice') : []
    }

  }
}
</script>

<style scoped>

</style>