<template>
  <v-container>
    <can-fly-notification
        :class="{'mt-3': $vuetify.breakpoint.smAndDown, 'mt-16': $vuetify.breakpoint.mdAndUp}" :items="flyingStatus"/>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <sidebar-menu title="Flights" :items="sortiePageSidebarMenuItems"/>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface mb-10">
          <router-view/>
        </v-sheet>
      </v-col>
    </v-row>
    <walk-out-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
    <book-in-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
    <cancel-sortie-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
    <recover-sortie-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
    <delete-sortie-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
    <view-sortie-modal :loading="loading" v-on:refreshSorties="refreshSorties"/>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import SidebarMenu from "@/components/shared/SidebarMenu";
import CanFlyNotification from "@/components/widgets/CanFlyNotification";
import WalkOutModal from "@/components/modals/WalkOutModal";
import RecoverSortieModal from "@/components/modals/RecoverSortieModal";
import DeleteSortieModal from "@/components/modals/DeleteSortieModal";
import CancelSortieModal from "@/components/modals/CancelSortieModal";
import ViewSortieModal from "@/components/modals/ViewSortieModal";
import BookInModal from "@/components/modals/BookInModal";

export default {
  name: "Sorties",
  components: {
    CanFlyNotification,
    SidebarMenu,
    WalkOutModal,
    RecoverSortieModal,
    DeleteSortieModal,
    CancelSortieModal,
    ViewSortieModal,
    BookInModal
  },
  computed: {
    ...mapState("app", ["loading", "sortiePageSidebarMenuItems"]),
    ...mapState("group", ["groups","activeGroup"]),
    ...mapState("user",["flyingStatus"]),
    ...mapState("sorties", ["sortieSearchQueryParams"]),
    activeGroupDetails(){
      return this.groups.find(g => g._id == this.activeGroup)
    },
  },
  methods:{
    ...mapActions("sorties", ["setSorties", "searchSortiesByQuery"]),
    async refreshSorties(){
      await this.searchSortiesByQuery(this.sortieSearchQueryParams)
    }
  }

};
</script>