<template>
  <v-dialog
      v-model="manageWalkOutDialog"
      :loading="loading"
      :disabled="!flyingStatus.canFly || loading"
      width="90%"
      max-width="800px"
      v-if="activeSortie"
      persistent
      >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Ready to Walk Out?
      </v-card-title>
    </v-card>
    <v-stepper v-model="outBriefActiveStep" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="outBriefActiveStep > 1" step="1">Review Crew</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="outBriefActiveStep > 2" step="2" v-if="riskManagementItems.length>0">Risk Management</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="outBriefActiveStep > 3" step="3">Review ETD</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="outBriefActiveStep > 4" step="4">Outbrief</v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" v-if="activeSortie.crewList">
          <sortie-crew-list :value.sync="activeSortie.crewList" :disabled="loading"/>
          <v-btn color="primary" @click="outBriefActiveStep = riskManagementItems.length>0 ? 2:3" :disabled="!crewListValid">Continue</v-btn>

          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <flight-brief-items :items="riskManagementItems" v-model="acceptedRiskItems" />
          <v-row dense>
            <v-col>
              <div class="text-body-2">
              Risk Appetite
              </div>
            </v-col>
            <v-col>
              <app-progress-circular :width="3" :value="acceptedRiskItems.length" :max="riskManagementItems.length" />
            </v-col>
          </v-row>


          <v-divider/>

          <v-btn color="primary" @click="outBriefActiveStep = 3">Continue</v-btn>

          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="pt-2">
            <app-select :items="items" :item-text="items.text" :item-value="items.value" label="I will be in the cockpit" v-model="walkaroundTime"/>
          </div>
          <div class="pt-2">
            <app-select :items="taxiItems" :item-text="items.text" :item-value="items.value" label="Start up and Taxi out time will be approximately" v-model="taxiTime"/>
          </div>
          <div class="text-body-1 pt-2">Note: On clicking OK, your ETD and revised ETA will be adjusted to reflect the new departure time.
          </div>
          <v-alert type="info" dense><strong>This action cannot be undone. If you click it in error you will need to Cancel
            and create a new Sortie.</strong></v-alert>

          <v-divider></v-divider>
          <v-btn color="primary" @click="outBriefActiveStep = 4">Continue</v-btn>

          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-list flat>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="(item, i) in outBriefItems"
                  :key="i" disabled
              >
                <v-list-item-icon>
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Out brief items reviewed and discussed?: {{ outBriefComplete ? 'Yes':'No'}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="text-caption">Toggle</div>
                  <div>
                    <v-switch v-model="outBriefComplete"/>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Name of Outbriefer</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field v-model="outbriefer" label="" outlined/>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class="primary" @click="outbriefer='Self'" small><v-icon left>mdi-account-tie</v-icon>Self</v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider/>
          <v-btn color="primary" @click="walkOut" :disabled="!outBriefComplete || outbriefer.length < 2"><v-icon left>mdi-airplane-takeoff</v-icon>Lets Go!</v-btn>

          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import moment from "moment";
// import DurationSelector from "@/components/shared/DurationSelector";
import AppSelect from "@/components/app/Select";
import AppProgressCircular from "@/components/app/ProgressCircular";
import FlightBriefItems from "@/components/FlightBriefItems";
import SortieCrewList from "@/components/shared/SortieCrewList";

export default {
  name: "WalkOutModal",
  components: {SortieCrewList, FlightBriefItems, AppProgressCircular, AppSelect},
  props: {
    loading: {
      type: Boolean
    },
  },
  mounted() {
    const now = moment().add(15, 'minutes')
    this.eTD = moment.duration(now.format('HH').toString(), 'hours')
        .add(now.format('HH').toString(), 'minutes').as('minutes')
    this.localETD = now.format('HH:mm:ss').toString()
  },
  async beforeDestroy() {
    await this.setActiveSortie(null);
  },
  data: () => {
    return {
      acceptedRiskItems:[],
      outBriefComplete:false,
      outBriefActiveStep:1,
      outbriefer:'',
      walkoutTime: 0,
      items: [{text: 'In Cockpit Already (Now)', value: 0},
        {text: 'In 5 mins', value: 5},
        {text: 'In 10 mins', value: 10},
        {text: 'In 15 mins', value: 15},
        {text: 'In 20 mins', value: 20},
        {text: 'In 25 mins', value: 25},
        {text: 'In 30 mins', value: 30}],
      walkaroundTime: 5,
      taxiItems: [
        {text: '5 mins', value: 5},
        {text: '10 mins', value: 10},
        {text: '15 mins', value: 15},
        {text: '20 mins', value: 20},
        {text: '25 mins', value: 25},
        {text: '30 mins', value: 30}],
      taxiTime: 10
    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("user", ["flyingStatus"]),
    ...mapState("aircraft", ["aircraft"]),
    ...mapState("group", ["groups"]),
    ...mapState("sorties", ["manageWalkOutDialog","activeSortie"]),

    crewListValid() {
      const crewNumber = this.activeSortie.crewList.filter(c=> c.name !== '').filter(c=> c.role !== '')
      const pIC = this.activeSortie.crewList.filter( c=> c.role ==='Pilot in Command')

      return (crewNumber.length > 0) && (pIC.length = 1)
    },

    aircraftMaxOccupancy() {
      if (this.activeSortie.registration == undefined) {
        return 1
      }
      return this.aircraft.find(a => a.registration === this.activeSortie.registration).aircraftType.numberOfSeats

    },

    selectedGroup() {
      return this.activeSortie ? this.groups.find(g => g._id === this.activeSortie.group) : null
    },

    riskManagementItems() {
      return this.activeSortie && this.selectedGroup ? this.selectedGroup.flightRiskManagementItems : []
    },
    riskAppetiteScore(){
      return this.acceptedRiskItems.length
    },
    riskAppetiteMaxScore(){
      return this.riskManagementItems.length
    },

    outBriefItems() {
      return this.activeSortie && this.selectedGroup ? this.selectedGroup.outbriefItems:[]
    }
  },

  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setSorties", "setActiveSortie","setManageWalkOutDialog"]),

    async closeDialog(){
      this.acceptedRiskItems = []
      this.outBriefComplete = false,
      this.outBriefActiveStep = 1
      this.outbriefer = ''
      await this.setActiveSortie(null)
      await this.setManageWalkOutDialog(false)
    },
    async walkOut() {
      try {
        await this.setLoading(true)
        const etdPostWalkout = moment(new Date()).utc(false).add(this.walkaroundTime, 'minutes').add(this.taxiTime, 'minutes').unix()
        const sUTTO = moment(new Date()).utc(false).add(this.walkaroundTime, 'minutes').unix()
        const sortieUpdateDetails =  { outbriefer: this.outbriefer,
          acceptedRiskItems:this.acceptedRiskItems,
          riskAppetiteScore:this.riskAppetiteScore,
          riskAppetiteMaxScore:this.riskAppetiteMaxScore,
          crewList:this.activeSortie.crewList,
        }

        await SortieHttp.walkOut(this.activeSortie._id, sortieUpdateDetails, sUTTO, etdPostWalkout);
        this.$emit('refreshSorties')
        await this.closeDialog()
        await this.setLoading(false)
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>