<template>
  <app-dialog
      v-model="this.manageViewSortieDialog"
      :useIcon="true"
      iconImage="eye"
      buttonText="View"
      title="Sortie Information"
      v-on:togglePrimary="toggleDialog"
      v-on:toggleSecondary="closeDialog"
      v-on:toggleDialog="toggleDialog"
      :show-primary="false"
      v-if="activeSortie">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-subheader dense>Aircraft</v-subheader>
          <v-divider/>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex flex-wrap xs6 md3>
          <div class="text-body-1">Date of Flight:</div>
        </v-flex>
        <v-flex flex-wrap xs6 md3>
          <div class="text-body-2">{{ activeSortie.eTD.localTimestamp | moment('DD MMM YYYY') }}</div>
        </v-flex>
        <v-flex flex-wrap xs6 md3>
          <div class="text-body-1">Aircraft:</div>
        </v-flex>
        <v-flex flex-wrap xs6 md3>
          <div class="text-body-2">{{ activeSortie.registration }}</div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-subheader dense>Route</v-subheader>
          <v-divider/>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-body-1">Departure:</div>
        </v-flex>
        <v-flex xs6>
          <div class="text-body-2">{{ activeSortie.pointOfDepartureICAO }}</div>
          <div class="">{{ activeSortie.pointOfDepartureName }}</div>
        </v-flex>
        <v-flex xs6>
          <div class="text-body-1">Arrival:</div>
        </v-flex>
        <v-flex xs6>
          <div class="text-body-2">{{ activeSortie.pointOfArrivalICAO }}</div>
          <div class="">{{ activeSortie.pointOfArrivalName }}</div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-subheader dense>Times</v-subheader>
          <v-divider/>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs4>
          <div class="text-body-1">Times</div>
        </v-flex>
        <v-flex xs4>
          <div class="text-body-1">Departure:</div>
        </v-flex>
        <v-flex xs4>
          <div class="text-body-1">Arrival:</div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs4>
          <div class="text-body-1">Estimated:</div>
        </v-flex>
        <v-flex xs4>
          <div class="text-body-2" v-if="activeSortie.eTD">{{ activeSortie.eTD.localTimestamp | moment('HH:mm') }} L /
            {{ activeSortie.eTD.utcTimestamp | moment('HH:mm') }} Z
          </div>
        </v-flex>
        <v-flex xs4 v-if="activeSortie.eTA">
          <div class="text-body-2" v-if="activeSortie.eTA">{{ activeSortie.eTA.localTimestamp | moment('HH:mm') }} L /
            {{ activeSortie.eTA.utcTimestamp | moment('HH:mm') }} Z
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="activeSortie.captainDeclaration===true">
        <v-flex xs4>
          <div class="text-body-1">Actual:</div>
        </v-flex>
        <v-flex xs4>
          <div class="text-body-2" v-if="activeSortie.aTD">{{ activeSortie.aTD.localTimestamp | moment('HH:mm') }} L /
            {{ activeSortie.aTD.utcTimestamp | moment('HH:mm') }} Z
          </div>
        </v-flex>
        <v-flex xs4 v-if="activeSortie.aTA">
          <div class="text-body-2" v-if="activeSortie.aTA">{{ activeSortie.aTA.localTimestamp | moment('HH:mm') }} L /
            {{ activeSortie.aTA.utcTimestamp | moment('HH:mm') }} Z
          </div>
        </v-flex>
      </v-layout>
      <!--      <v-layout row wrap>-->
      <!--        <v-flex xs12>-->
      <!--          <v-subheader dense>Takeoffs & Landings</v-subheader>-->
      <!--          <v-divider/>-->
      <!--        </v-flex>-->
      <!--      </v-layout>-->
      <!--      <v-layout row wrap>-->
      <!--        <v-flex xs4></v-flex>-->
      <!--        <v-flex xs4>Take-off</v-flex>-->
      <!--        <v-flex xs4>Landing</v-flex>-->
      <!--      </v-layout>-->
      <!--      <v-layout row wrap-->
      <!--                v-if="activeSortie.takeOffs.dayTakeOffs || activeSortie.landings.dayLandings">-->
      <!--        <v-flex xs4>Day</v-flex>-->
      <!--        <v-flex xs4>{{activeSortie.takeOffs.dayTakeOffs}}</v-flex>-->
      <!--        <v-flex xs4>{{activeSortie.landings.dayLandings}}</v-flex>-->
      <!--      </v-layout>-->
      <!--      <v-layout row wrap-->
      <!--                v-if="activeSortie.takeOffs.nightTakeOffs || activeSortie.landings.nightLandings">-->
      <!--        <v-flex xs4>Night</v-flex>-->
      <!--        <v-flex xs4>{{activeSortie.takeOffs.nightTakeOffs}}-->
      <!--        </v-flex>-->
      <!--        <v-flex xs4>{{activeSortie.landings.nightLandings}}-->
      <!--        </v-flex>-->
      <!--      </v-layout>-->
      <v-layout row wrap>
        <v-flex xs12>
          <v-subheader dense>Crew</v-subheader>
          <v-divider/>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <div class="text-body-2" v-for="crew in activeSortie.crewList" :key="crew.person">{{ crew.person }}
            ({{ crew.role }})
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-subheader dense>Other Information</v-subheader>
          <v-divider/>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-body-1">Duty:</div>
        </v-flex>
        <v-flex xs6>
          <div class="text-body-2">{{
              activeSortie.route == null ? 'No Route Information provided' : activeSortie.route
            }}
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs6>
          <div class="text-body-1">Sortie Status:</div>
        </v-flex>
        <v-flex xs6>
          <flight-strip-status-chip :status="activeSortie.sortieStatus"/>
        </v-flex>
      </v-layout>
    </v-container>
  </app-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import AppDialog from "@/components/app/Dialog";
import FlightStripStatusChip from "@/components/shared/FlightStripStatusChip";

export default {
  name: "BookInModal",
  components: {
    FlightStripStatusChip,
    AppDialog,
  },
  props: {
    loading: {
      type: Boolean
    },
  },
  data() {
    return {
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      isLoading: false,
    };
  },
  computed: {
    ...mapState("sorties", ["activeSortie", "manageViewSortieDialog"]),
    ...mapState("app", ["loggedInUser"]),

    validTakeOffTimes() {
      if (this.activeSortie.aTA > this.activeSortie.aTD) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions("sorties", ["setSorties", "setActiveSortie", "setManageViewSortieDialog"]),
    ...mapActions("app", ["setToast"]),
    async closeDialog() {
      await this.setActiveSortie(null)
      await this.setManageViewSortieDialog(false)
    },
    async toggleDialog() {
      this.setActiveSortie(this.sortie)
    },
    async saveSortie() {

      if (this.$refs.form.validate()) {
        try {
          await SortieHttp.updateSortie(this.activeSortie);
          this.$emit('refreshSorties')
          this.setToast({color: 'success', text: 'Booked In successfully', show: true});
          await this.closeDialog()
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
          } else {
            console.log(err);
          }

        }
      }
    }
  }
};
</script>

<style scoped>

.v-chip.late {
  background: tomato;
}

.v-chip.complete {
  background: mediumpurple;
}

.v-chip.awaiting.flight.details {
  background: orange;
}

.v-chip.walking.to.aircraft {
  background: blue;
}

.v-chip.airborne {
  background: blue;
}


.v-chip.delayed.departure {
  background: mediumorchid;
}

.v-chip.scheduled {
  background: orange;
}

</style>