<template>
  <v-dialog
      :value="value"
      persistent
      width="500"
  >
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <slot name="toggle-button">-->
<!--        <div class="text-center">-->
<!--          <v-btn-->
<!--              color="primary"-->
<!--              :block="!useIcon"-->
<!--              :tile="!useIcon"-->
<!--              :fab="useIcon"-->
<!--              :disabled="disabled"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--              :x-small="useIcon"-->
<!--              @click="$emit('toggleDialog')">-->
<!--            <v-icon  v-if="useIcon">mdi-{{iconImage}}</v-icon>-->
<!--            <div v-else>{{ buttonText }}</div>-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </slot>-->
<!--    </template>-->

    <app-card>
      <v-card-title class="headline grey lighten-2">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <slot/>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <app-btn
            v-if="showSecondary"
            :loading="loading"
            :disabled="disabled"
            color="secondary"
            @click="toggleSecondary()"
        >
          {{ secondaryButtonText }}
        </app-btn>
        <slot name="actions">
          <app-btn
              v-if="showPrimary"
              :loading="loading"
              :disabled="disabled || disableButtons"
              color="primary"
              @click="togglePrimary()"
          >
            {{ primaryButtonText }}
          </app-btn>
        </slot>
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>

<script>
import AppCard from "@/components/app/Card"
import AppBtn from "@/components/app/Btn";

export default {
  name: "AppDialog",
  components: {AppBtn, AppCard},
  props: {
    value: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    headingColor: {
      type: String,
      default: "Primary"
    },
    title: {
      type: String,
      default: "Confirm"
    },
    buttonText: {
      type: String,
      default: "Activate"
    },
    openButtonText: {
      type: String,
      default: "primary"
    },
    primaryButtonText: {
      type: String,
      default: "OK"
    },
    secondaryButtonText: {
      type: String,
      default: "Close"
    },
    disableButtons:{
      type: Boolean,
      default: false
    },
    showPrimary:{
      type: Boolean,
      default: true
    },
    showSecondary:{
      type: Boolean,
      default: true
    },
    useIcon: {
      type: Boolean,
      default: false
    },
    iconImage: {
      type: String,
    }


  },
data()
{
  return {
    dialog: false,
  }
}
,
methods: {
  togglePrimary()
  {
    this.$emit('togglePrimary')
    // this.dialog = false
  }
,
  toggleSecondary()
  {
    this.$emit('toggleSecondary')
    // this.dialog = false
  }
}
}
</script>

<style scoped>

</style>