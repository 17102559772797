<template>
  <v-container>
    <v-row dense>
      <v-col cols="6" v-for="item in items" :key="item.name" dense>
        <div class="text-body-2">{{ item.name }}</div>
        <div><v-switch
            dense
            v-model="selected"
            :value="item.name"
            @change="updateValue($event)"
        ></v-switch></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FlightBriefItems",
  props: {
    value: {
      type:Array
    },
    items:{
      type:Array
    }
  },
  data: () => {
    return {
      selected: [],
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    }
  }
}
</script>

<style scoped>

</style>