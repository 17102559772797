import { render, staticRenderFns } from "./FlightBriefItems.vue?vue&type=template&id=6aaeb480&scoped=true"
import script from "./FlightBriefItems.vue?vue&type=script&lang=js"
export * from "./FlightBriefItems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aaeb480",
  null
  
)

export default component.exports