<template>
  <app-dialog
      v-model="this.manageRecoverSortieDialog"
      buttonText="Recover"
      title="Recover Sortie?"
      v-on:togglePrimary="recoverSortie"
      v-on:toggleSecondary="closeDialog"
      v-if="activeSortie">
    <div class="pt-2">Are you sure you want to recover this Sortie?</div>
  </app-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import AppDialog from "@/components/app/Dialog";

export default {
  name: "RecoverSortieModal",
  components: {AppDialog},
  props: {
    loading: {
      type: Boolean
    },
    sortie: {
      type: Object
    },
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("sorties", ["manageRecoverSortieDialog", "activeSortie"]),
    ...mapState("group", ["groups","activeGroup"]),
  },

  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setManageRecoverSortieDialog","setActiveSortie","setSorties"]),
    async closeDialog() {
      await this.setActiveSortie(null)
      await this.setManageRecoverSortieDialog(false)
    },
    async recoverSortie() {
      try {
        await this.setLoading(true)
        await SortieHttp.recoverSortie(this.activeSortie._id);
        this.$emit('refreshSorties')
        await this.setLoading(false)
        await this.closeDialog()
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>