import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.value,"persistent":"","width":"500"}},[_c('app-card',[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._t("default")],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.showSecondary)?_c('app-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.disabled,"color":"secondary"},on:{"click":function($event){return _vm.toggleSecondary()}}},[_vm._v(" "+_vm._s(_vm.secondaryButtonText)+" ")]):_vm._e(),_vm._t("actions",function(){return [(_vm.showPrimary)?_c('app-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.disabled || _vm.disableButtons,"color":"primary"},on:{"click":function($event){return _vm.togglePrimary()}}},[_vm._v(" "+_vm._s(_vm.primaryButtonText)+" ")]):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }