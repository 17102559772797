<template>
  <app-dialog
      v-model="this.manageDeleteSortieDialog"
      buttonText="Delete"
      title="Delete the Sortie?"
      v-on:togglePrimary="deleteSortie"
      v-on:toggleSecondary="closeDialog"
      v-if="activeSortie">
    <div class="pt-2">Are you sure you want to delete the Sortie?</div>
  </app-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import AppDialog from "@/components/app/Dialog";

export default {
  name: "DeleteSortieModal",
  components: {AppDialog},
  props: {
    loading: {
      type: Boolean
    },
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("sorties", ["manageDeleteSortieDialog", "activeSortie"]),
  },

  methods: {
    ...mapActions("sorties", ["setManageDeleteSortieDialog","setActiveSortie", "setSorties"]),
    async closeDialog() {
      await this.setActiveSortie(null)
      await this.setManageDeleteSortieDialog(false)
    },
    async deleteSortie() {
      try {
        await SortieHttp.deleteSortie(this.activeSortie._id);
        this.$emit('refreshSorties')
        await this.closeDialog()
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>