<template>
  <v-progress-circular
    :rotate="-90"
    :size="100"
    :width="15"
    :value="percentage"
    :color="color">
  {{number}}
  </v-progress-circular>
</template>

<script>
export default {
  name: "AppProgressCircular",
  props:{
    width:{},
    value:{},
    max:{}
  },
  computed:{
    percentage(){
      return (this.value/this.max)*100
    },
    color() {
      return this.percentage > 75 ? 'red': this.percentage <50 ? 'green': 'orange'
    }
  },
  data(){
    return{
     number:0
    }
  },
  watch:{
    value(newValue){
      this.number =newValue
    }
  }
}
</script>

<style scoped>

</style>