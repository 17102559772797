<template>
  <v-dialog
      v-model="manageSortieBookInDialog"
      :loading="loading"
      width="600px"
      v-if="activeSortie"
      persistent
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Book-in for Sortie
      </v-card-title>
    </v-card>
    <v-stepper v-model="activeStep" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="activeStep>1" step="1">Flight Times</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="activeStep>2" step="2">Logbook Times</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="activeStep>3" step="3">Other Items</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="activeStep > 4" step="4">Inbrief</v-stepper-step>

      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <v-row>
              <v-col cols="9">
                <div class="text-caption grey--text my-2">Local Departure Date:</div>
                <div>
                  <span class="text-body-1"> {{ localATDDate | moment('DD MMM YY') }}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <app-btn small fab @click="localATDDateIsReadOnly=!localATDDateIsReadOnly">
                  <v-icon>mdi-{{ localATDDateIsReadOnly ? 'pencil' : 'cancel' }}</v-icon>
                </app-btn>
              </v-col>
              <v-col cols="12">
                <app-date-picker v-if="!localATDDateIsReadOnly" v-model="localATDDate" :max="localATADate"/>
              </v-col>
              <v-col cols="6">
                <div class="text-caption grey--text my-2">Local Departure Time:</div>
              </v-col>
              <v-col cols="6">
                <div>
                  <app-time-picker v-model="localATDTime" :allowed-minutes="5"
                                   :max="localATADate == localATDDate ? localATATime: undefined"/>
                </div>
              </v-col>

              <v-col cols="9">
                <div class="text-caption grey--text my-2">Local Arrival Date:</div>
                <div>
                  <span class="text-body-1"> {{ localATADate | moment('DD MMM YY') }}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <app-btn small fab @click="localATADateIsReadOnly=!localATADateIsReadOnly">
                  <v-icon>mdi-{{ localATADateIsReadOnly ? 'pencil' : 'cancel' }}</v-icon>
                </app-btn>
              </v-col>
              <v-col cols="12">
                <app-date-picker v-if="!localATADateIsReadOnly" v-model="localATADate" :min="localATDDate"/>
              </v-col>
              <v-col cols="6">
                <div class="text-caption grey--text my-2">Local Arrival Time:</div>
              </v-col>
              <v-col cols="6">
                <div>
                  <app-time-picker v-model="localATATime" :allowed-minutes="5"
                                   :min="localATADate == localATDDate ? localATDTime: undefined"/>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-toolbar dense>
            <v-spacer/>
            <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="primary" @click="activeStep=2" :disabled="!step1Complete">
              Next
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-alert type="info">Logbook Times</v-alert>
          <v-subheader>Aircraft</v-subheader>
          <v-divider/>
          <v-container>
            <v-row dense>
              <v-col cols="6">
                <div class="text-caption grey--text my-2">Total Flight Time:</div>
              </v-col>
              <v-col cols="6">
                <!--                <app-time-picker v-model="localAircraftFlightTime" max="8:00"/>-->
                <app-time-picker v-model="localAircraftFlightTime" :allowed-minutes="5" max="8:00"/>
                <v-tooltip bottom>
                  <template #activator="{ on: onTooltip }">
                    <app-btn @click="localPilotLogbookTotalTime=localAircraftFlightTime" v-on="{ ...onTooltip }">
                      <v-icon>mdi-content-copy</v-icon>
                    </app-btn>
                  </template>
                  <span>Copy aircraft airborne time to Pilot's logbook time</span>
                </v-tooltip>
              </v-col>
              <v-col cols="6">
                <div class="text-caption grey--text my-2">Total Landings:</div>
              </v-col>
              <v-col cols="6">
                <div class="caption grey--text">Landings</div>
                <div>
                  <v-slider
                      v-model="localLandingDay"
                      class="align-center"
                      :max="50"
                      :min="1"
                      hide-details
                  >
                    <template v-slot:prepend>
                      <v-text-field
                          v-model="localLandingDay"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                      ></v-text-field>
                    </template>
                  </v-slider>
                </div>
              </v-col>
              <!--              // Reserved for future if a group wants Hobbs or Tacho-->
              <!--              <v-col cols="4">-->
              <!--                <div class="text-caption grey&#45;&#45;text my-2">Tacho:</div>-->
              <!--              </v-col>-->
              <!--              <v-col cols="4">-->
              <!--                <v-text-field-->
              <!--                    ref="localAircraftTachoStart"-->
              <!--                    v-model="localAircraftTachoStart"-->
              <!--                    :rules="[() => !!localAircraftTachoStart || 'This field is required']"-->
              <!--                    label="Tacho Start"-->
              <!--                    required-->
              <!--                    placeholder=""/>-->
              <!--              </v-col>-->
              <!--              <v-col cols="4">-->
              <!--                <v-text-field-->
              <!--                    ref="localAircraftTachoEnd"-->
              <!--                    v-model="localAircraftTachoEnd"-->
              <!--                    :rules="[() => !!localAircraftTachoEnd || 'This field is required']"-->
              <!--                    label="Tacho End"-->
              <!--                    required-->
              <!--                    placeholder=""/>-->
              <!--              </v-col>-->

              <!--              <v-col cols="4">-->
              <!--                <div class="text-caption grey&#45;&#45;text my-2">Hobbs:</div>-->
              <!--              </v-col>-->
              <!--              <v-col cols="4">-->
              <!--                <v-text-field-->
              <!--                    ref="localAircraftHobbsStart"-->
              <!--                    v-model="localAircraftHobbsStart"-->
              <!--                    :rules="[() => !!localAircraftHobbsStart || 'This field is required']"-->
              <!--                    label="Hobbs Start"-->
              <!--                    required-->
              <!--                    placeholder=""/>-->
              <!--              </v-col>-->
              <!--              <v-col cols="4">-->
              <!--                <v-text-field-->
              <!--                    ref="localAircraftHobbsEnd"-->
              <!--                    v-model="localAircraftHobbsEnd"-->
              <!--                    :rules="[() => !!localAircraftHobbsEnd || 'This field is required']"-->
              <!--                    label="Hobbs End"-->
              <!--                    required-->
              <!--                    placeholder=""/>-->
              <!--              </v-col>-->
            </v-row>
          </v-container>

          <v-subheader>Pilot</v-subheader>

          <v-divider/>
          <v-container>
            <v-row dense>
              <v-col cols="6">
                <div class="text-caption grey--text my-2">Logbook Total Time:</div>
              </v-col>
              <v-col cols="6">
                <app-time-picker v-model="localPilotLogbookTotalTime" :allowed-minutes="5" max="8:00"/>
              </v-col>
            </v-row>
          </v-container>

          <v-toolbar dense>
            <v-btn color="primary" @click="activeStep=1">
              <v-icon left>mdi-chevron-left</v-icon>
              Back
            </v-btn>
            <v-spacer/>
            <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="primary" @click="activeStep=3" :disabled="!step2Complete">
              Next
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>


        </v-stepper-content>
        <v-stepper-content step="3">
          <v-form class="" ref="form">
            <v-container>
              <v-row>

                <v-col cols="12">
                  <div class="text-caption">
                    Were the flight objectives achieved? (mandatory)
                  </div>
                  <div>
                    <v-select outlined :items="flightResultItems" v-model="localFlightResult"/>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-caption">
                    Notes for Pilot Logbook (optional)
                  </div>
                  <div>
                    <v-text-field
                        label="Pilot Logbook Remarks"
                        outlined
                        clearable
                        v-model="localRemarks"
                    />
                  </div>
                </v-col>
                <v-col>
                  <v-checkbox
                      v-model="localCaptainDeclaration"
                      :label="'I confirm the details above are a true record of this sortie.'"
                      :rules="[value => !!value || 'Required.']"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-divider/>
          <v-toolbar dense>
            <v-btn color="primary" @click="activeStep=2">
              <v-icon left>mdi-chevron-left</v-icon>
              Back
            </v-btn>
            <v-spacer/>
            <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="primary" @click="activeStep=4" :disabled="!step3Complete">
              Inbrief
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-list flat>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="(item, i) in inBriefItems"
                  :key="i" disabled
              >
                <v-list-item-icon>
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>In brief items reviewed actions carried out? {{ inBriefComplete ? 'Yes':'No'}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="text-caption">Toggle</div>
                  <div>
                    <v-switch v-model="inBriefComplete"/>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-toolbar dense>
            <v-btn color="primary" @click="activeStep=3">
              <v-icon left>mdi-chevron-left</v-icon>
              Back
            </v-btn>
            <v-spacer/>
            <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="primary" @click="saveSortie" :disabled="!formComplete">
              <v-icon left>mdi-home</v-icon>
              Book In!
            </v-btn>
          </v-toolbar>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {SortieHttp} from "../../http/SortieHttp";
import moment from "moment";
import AppBtn from "@/components/app/Btn";
import AppDatePicker from "@/components/app/DatePicker";
import AppTimePicker from "@/components/app/TimePicker";
import {intervalToDuration} from "date-fns";
import * as duration from 'duration-fns'
import {toHHMMSS} from "@/util/helpers";

export default {
  name: "BookInModal",
  components: {
    AppTimePicker,
    AppDatePicker,
    AppBtn
  },
  props: {
    loading: {
      type: Boolean
    },
  },
  data() {
    return {
      canCopy: false,
      inBriefComplete: false,
      localFlightResult: '',
      flightResultItems: [{text: 'Fully', value: 'dco'}, {text: 'Partially', value: 'dpco'}, {
        text: 'No',
        value: 'dnco'
      }],
      // localAircraftFlightTime: '',
      localAircraftTachoStart: 0,
      localAircraftTachoEnd: 0,
      localAircraftHobbsStart: 0,
      localAircraftHobbsEnd: 0,
      localPilotLogbookTotalTime: '',
      activeStep: 1,
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      localATDDate: null,
      localATDDateIsReadOnly: true,
      localATDTime: null,
      localATADate: null,
      localATADateIsReadOnly: true,
      localATATime: null,
      localTakeoffDay: 1,
      localLandingDay: 1,
      localRemarks: null,
      localCaptainDeclaration: null,
      rules: {
        required: value => !!value || 'Required.',
      }
    };
  },
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  computed: {
    ...mapState("sorties", ["activeSortie", "manageSortieBookInDialog"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("group", ["groups"]),

    localAircraftFlightTime() {
      return this.validTakeOffTimes ? toHHMMSS(duration.toSeconds(intervalToDuration({
        start: new Date(this.aTDLocalTimestamp * 1000),
        end: new Date(this.aTALocalTimestamp * 1000)
      }))) : ''
    },

    validTakeOffTimes() {
      if (this.aTALocalTimestamp > this.aTDLocalTimestamp) {
        return true
      }
      return false
    },
    aTDLocalTimestamp() {
      if (this.localATDTime === null || this.localATDDate === null) {
        return
      }
      return moment(this.localATDDate + ' ' + this.localATDTime).unix()
    },
    aTALocalTimestamp() {
      if (this.localATATime === null || this.localATADate === null) {
        return
      }
      return moment(this.localATADate + ' ' + this.localATATime).unix()
    },

    selectedGroup() {
      return this.activeSortie ? this.groups.find(g => g._id === this.activeSortie.group) : null
    },

    inBriefItems() {
      return this.activeSortie && this.selectedGroup ? this.selectedGroup.inbriefItems : []
    },


    step1Complete() {
      return this.localATDDate && this.localATDDate && this.localATDTime && this.localATATime
    },

    step2Complete() {
      return this.localPilotLogbookTotalTime && this.localAircraftFlightTime
    },

    step3Complete() {
      return this.localLandingDay && this.localFlightResult && this.localCaptainDeclaration
    },

    step4Complete() {
      return this.inBriefComplete
    },

    formComplete() {
      return this.step1Complete && this.step2Complete && this.step3Complete && this.step4Complete
    }
  },
  watch: {
    async manageSortieBookInDialog(newVal) {
      if (newVal) {
        await this.toggleDialog()
      }
    }
  },
  methods: {
    ...mapActions("sorties", ["setSorties", "setActiveSortie", "setManageSortieBookInDialog"]),
    ...mapActions("app", ["setLoading", "setToast"]),
    async closeDialog() {
      this.activeStep = 1
      await this.setActiveSortie(null)
      await this.setManageSortieBookInDialog(false)
    },

    async copyToClipboard(s) {
      await navigator.clipboard.writeText(s);
      this.setToast({color: 'success', text: 'password copied to clipboard', show: true})
    },

    formatTimestamp(timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    },

    getTimeInSeconds(duration) {
      let a = duration.split(':');
      return (+a[0]) * 60 * 60 + (+a[1]) * 60;
    },

    async toggleDialog() {
      const today = new Date()
      this.localATDDate = today.toISOString().substring(0, 10)
      this.localATDTime = null
      this.localATADate = today.toISOString().substring(0, 10)
      this.localATATime = null
      this.localTakeoffDay = 1
      this.localLandingDay = 1
      this.localRemarks = null
      this.localCaptainDeclaration = null
    },
    async saveSortie() {
      if (this.$refs.form.validate()) {
        try {
          //let newSortie = this.sortie
          this.activeSortie.tzOffset = 0
          this.activeSortie.aTD = this.aTDLocalTimestamp
          this.activeSortie.aTA = this.aTALocalTimestamp
          this.activeSortie.dayTakeOffs = this.localTakeoffDay
          this.activeSortie.dayLandings = this.localLandingDay
          this.activeSortie.remarks = this.localRemarks
          this.activeSortie.captainDeclaration = this.localCaptainDeclaration
          this.activeSortie.localFlightResult = this.localFlightResult
          this.activeSortie.aircraftFlightTime = this.localAircraftFlightTime
          this.activeSortie.aircraftTachoStart = +this.localAircraftTachoStart
          this.activeSortie.aircraftTachoEnd = +this.localAircraftTachoEnd
          this.activeSortie.aircraftHobbsStart = +this.localAircraftHobbsStart
          this.activeSortie.aircraftHobbsEnd = +this.localAircraftHobbsEnd
          this.activeSortie.pilotLogbookTotalTime = this.localPilotLogbookTotalTime

          await this.setLoading(true)
          await SortieHttp.bookIn(this.activeSortie);
          this.$emit('refreshSorties')
          await this.setLoading(false)
          await this.closeDialog()
          this.setActiveSortie(null)
          this.setToast({color: 'success', text: 'Booked In successfully', show: true});
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
          } else {
            console.log(err);
          }
        }
      }
    }
  }
};
</script>